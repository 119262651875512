export function module_scroll() {
	//--------------------------------------------------------
	// Scroll Event
	//--------------------------------------------------------
	$(window).on('load scroll', function () {
		const obj = $(".scrollAnimation-obj");
		const upIn = $(".scrollAnimation-up");
		const downIn = $(".scrollAnimation-down");
		const leftIn = $(".scrollAnimation-left");
		const rightIn = $(".scrollAnimation-right");
		const listIn = $(".scrollAnimation-list");
		const maskIn = $(".scrollAnimation-mask");
		const parallaxYdown = $(".scrollAnimation-parallax-y-down");
		const parallaxYup = $(".scrollAnimation-parallax-y-up");

		const scroll = $(window).scrollTop();
		const windowH = $(window).height();

		obj.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).css({
					'opacity': '1'
				});
			}
		});

		upIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});

		downIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});

		leftIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});

		rightIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});

		listIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				var lists = $(this).children();
				lists.each(function (i) {
					$(this).delay(100 * i).animate({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				});
			}
		});

		//マスクアニメーション
		maskIn.each(function () {
			const objPos = $(this).offset().top;
			if (scroll > objPos - windowH + 100 || objPos < windowH) {
				$(this).addClass('active');
			}
		});

		//パララックスアニメーション Y軸 下方向
		parallaxYdown.each(function () {
			const objPos = $(this).offset().top;
			var current = 0

			current = (1 - (objPos - scroll) / windowH) * 100;
			/*if (current > 99.9) {
				current = 100;
			}*/

			if (scroll > objPos - windowH || objPos < windowH) {
				$(this).css({
					'transform': 'translate(0, calc(0% + ' + current + 'px)'
				});
			}
		});

		//パララックスアニメーション Y軸 上方向
		parallaxYup.each(function () {
			const objPos = $(this).offset().top;
			var current = 0

			current = (1 - (objPos - scroll) / windowH) * 100;

			if (scroll > objPos - windowH || objPos < windowH) {
				$(this).css({
					'transform': 'translate(0, calc(0% - ' + current + 'px)'
				});
			}
		});
	});
}