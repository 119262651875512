export function module_market() {
	$(function () {
		marketContents();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on("resize", function () {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function () {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
					marketContents();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//リサイズ用関数
		function marketContents() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			//メインビジュアルの画像を背景として指定
			$(".marketSlider__item img").each(function () {
        //メインビジュアルのsrcを取得
        var mainSrc = "url(" + $(this).attr("src") + ")";
        //取得したsrcを親の要素の背景として指定
        $(this).parents(".marketSlider__item").css("background-image", mainSrc);
      });

			//Slick SlideShow
			$(".marketSlider").slick({
        autoplay: true, // 自動でスクロール
        autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
        speed: 10000, // スライドが流れる速度を設定
        cssEase: "linear", // スライドの流れ方を等速に設定
        slidesToShow: 4, // 表示するスライドの数
        swipe: false, // 操作による切り替えはさせない
        arrows: false, // 矢印非表示
        pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
        pauseOnHover: false, // スライダーにマウスホバーした時にスライドを停止させるか
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 3, // 画面幅750px以下でスライド3枚表示
            },
          },
        ],
      });
		}
	});
}