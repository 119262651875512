export function module_index() {
	$(function () {
		topContents();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on("resize", function () {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function () {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
					topContents();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//リサイズ用関数
		function topContents() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			//メインビジュアルの画像を背景として指定
			$('.mainVisualBg__item img').each(function () {
				//メインビジュアルのsrcを取得
				var mainSrc = 'url(' + $(this).attr('src') + ')';
				//取得したsrcを親の要素の背景として指定
				$(this).parents(".mainVisualBg__item").css("background-image", mainSrc);
			});

			//Slick SlideShow
			$(".mainVisualBg").slick({
        autoplay: true,
        autoplaySpeed: 3500,
        dots: false,
        infinite: true,
        speed: 1800,
        arrows: false,
        pauseOnHover: false,
        fade: true,
      });
		}
	});
}