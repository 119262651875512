export function module_common() {
	$(function () {
		
		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		commonResize();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on("resize", function () {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function () {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
					commonResize();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//共通リサイズイベント
		function commonResize() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			if(window.innerWidth >= 1000) {
				//SPメニュー表示時の背景固定用クラスを削除
				$('body').removeClass('fixed');
				$('header, .gNav, .gNav__inner, .menuBtn').removeClass('active');
				$('.gNav, .gNav__inner, main').removeAttr('style');
				//クリックイベント削除
				$(".gNav").off("click");
				//スクロール時のヘッダー固定
				$(window).on('load scroll', function () {
					var headerHeight = $(".content__title").height();
					var navHeight = $(".header").height();

					if($(this).scrollTop() > headerHeight) {
						$(".header").addClass("fixed");
					} else {
						$(".header").removeClass("fixed");
					}
				});

			} else {

				$('.gNav').removeClass('fixed');

				//ヘッダー高さを取得
				var headerHeight = $('header').outerHeight();
				//メニュー上部に余白
				$('.gNav__inner').css('top', headerHeight);
				//スクロール時のヘッダー固定
				$(window).on('load scroll', function () {
					var headerHeight = $(".content__title").height();
					var navHeight = $(".header").height();

					if($(this).scrollTop() > headerHeight) {
						$(".header").addClass("fixed");
					} else {
						$(".header").removeClass("fixed");
					}
				});

				//--------------------------------------------------------
				// スマホ・タブレット時メニュー
				//--------------------------------------------------------
				var state = false;
				var scrollpos;

				$('.menuBtn, .gNav').on('click', function (event) {
					//event.preventDefault();

					if (state == false) {
						// 背景コンテンツ固定
						scrollpos = $(window).scrollTop();
						$('body').addClass('fixed').css({
							'top': -scrollpos
						});

						// グローバルメニュー表示
						$('header, .gNav, .gNav__inner, .menuBtn').addClass('active');
						state = true;
					} else {
						// 背景コンテンツ固定 解除
						$('body').removeClass('fixed').css({
							'top': 0
						});
						window.scrollTo(0, scrollpos);
						// グローバルメニュー非表示
						$('header, .gNav, .gNav__inner, .menuBtn').removeClass('active');
						state = false;
					}
				});
			}
		} //commonResize


		//--------------------------------------------------------
		// PAGETOP
		//--------------------------------------------------------
		var topBtn = $('.pagetop');
		topBtn.hide();
		$(window).scroll(function () {
			if ($(this).scrollTop() > 160) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});

		//スクロールしてトップ
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 1500, 'easeInOutQuart');
			return false;
		});
	});
}